<template>
  <div class="bkColor">
    <headTitle :title="title" :isHome="true"></headTitle>
    <div class="logo">
      <img class="logoImg" src="../assets/icon/logo_img.png" alt="" />
      <div class="logoTitle">大美项目管理</div>
      <van-button plain type="info" style="
          position: absolute;top: 0;right: 0; bottom: 0;left: 68%;margin: auto;width: 100px; " size="small"
        @click="goDataPanel" v-if="isShow">数据看板</van-button>
    </div>
    <div class="statistics">
      <div class="statisticsH">
        <div style="
            width: 30vw;
            height: 8vh;
            font-weight: 900;
            line-height: 8vh;
            text-align: left;
            box-sizing: border-box;
            padding-left: 5vw;
          ">
          统计
        </div>
        <div style="
            width: 30vw;
            height: 8vh;
            display: flex;
            justify-content: center;
            align-items: center;
          ">
          <img class="Himg" src="../assets/icon/sx_icon.png" alt="" />
          <van-dropdown-menu>
            <van-dropdown-item v-model="year" :options="option" @change="getData" />
          </van-dropdown-menu>
        </div>
      </div>
      <div class="dataDisplay" v-if="powerList.includes('1')">
        <div class="dataM">
          <div class="number">{{ total.totalProject || 0 }}</div>
          <div>项目数量</div>
        </div>
        <div class="dataM">
          <div class="number">
            {{ getTenThousand(total.totalPrice) || 0 }}元
          </div>
          <div>项目金额</div>
        </div>
        <div class="dataM">
          <div class="number">
            {{ getTenThousand(total.totalGathering) || 0 }}元
          </div>
          <div>项目收款</div>
        </div>
        <div class="dataM">
          <div class="number">
            {{ getTenThousand(total.totalPayment) || 0 }}元
          </div>
          <div>项目已付款</div>
        </div>
        <div class="dataM">
          <div class="number">
            {{ getTenThousand(total.totalReimbursement) || 0 }}
          </div>
          <div>项目已报销</div>
        </div>
        <div class="dataM" @click="goPage('/dataSummary')">
          <div style="height: 23px">
            <img style="width: 20px; height: 5px" src="../assets/icon/gd_icon.png" alt="" />
          </div>
          <div>更多数据</div>
        </div>
      </div>
      <div class="dataDisplay" v-else>
        <div style="
            text-align: center;
            width: 100%;
            line-height: 12vh;
            font-weight: 600;
          ">
          该模块正在开发中，敬请期待
        </div>
      </div>
    </div>
    <div class="ModuleOptions">
      <div class="options" @click="goPage('/pjList')">
        <img src="../assets/icon/xmlb_icon.png" alt="" />
        项目列表
      </div>
      <div class="options" @click="goPage('/newpj')">
        <img src="../assets/icon/xjxm_icon.png" alt="" />
        新建项目
      </div>
      <div class="options" @click="goPage('/progressReport')">
        <img src="../assets/icon/jdhb_icon.png" alt="" />
        进度汇报
      </div>
      <div class="options" v-show="powerList.includes('2') || powerList.includes('1')" @click="goPage('/pjCollection')">
        <img src="../assets/icon/xmfk_icon.png" alt="" />
        项目收款
      </div>
      <div class="options" @click="goPage('/pjPayment')">
        <img src="../assets/icon/xmfk_icon1.png" alt="" />
        付款列表
      </div>
      <div class="options" @click="goPage('/pjReimbursement')">
        <img src="../assets/icon/xmbx_icon.png" alt="" />
        报销列表
      </div>
      <div class="options" @click="goPage('/auditList')">
        <img src="../assets/icon/xmsh_icon.png" alt="" />
        项目审核
      </div>
      <!-- <div class="options" @click="goPage('/nonProject')">
        <img src="../assets/icon/xmsh_icon.png" alt="" />
        非项目花销
      </div> -->
      <div class="options" v-show="powerList.includes('2') || powerList.includes('1')"
        @click="goPage('/transactionAmount')">
        <img src="../assets/icon/xmsh_icon.png" alt="" />
        往来列表
      </div>
      <div class="options" v-show="powerList.length > 0" @click="goPage('/supplierList')">
        <img src="../assets/icon/xmsh_icon.png" alt="" />
        供应商列表
      </div>
      <div class="options" v-show="powerList.includes('2') || powerList.includes('1')" @click="goPage('/configList')">
        <img src="../assets/icon/xmsh_icon.png" alt="" />
        配置项列表
      </div>
    </div>
  </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import { DropdownMenu, DropdownItem, Button } from "vant";
import { ddShare } from '../units/dd'
import { getToken } from "../units/auth";
// import { getConfig } from '../api/api'
import { getProjectStatistics } from "../api/info";
import { tenThousand } from "../units/units";

import { configDetailList } from '../api/configDetail'

Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Button);

export default {
  name: "Home",
  components: {
    headTitle,
  },
  data() {
    return {
      title: "项目管理系统",
      year: 2024,
      option: [
        { text: "2020年", value: 2020 },
        { text: "2021年", value: 2021 },
        { text: "2022年", value: 2022 },
        { text: "2023年", value: 2023 },
        { text: "2024年", value: 2024 },
        { text: "2025年", value: 2025 },
        { text: "2026年", value: 2026 },
      ],
      total: {
        totalDoing: 0, //进行中
        totalFinish: 0, //总完成
        totalGathering: 0, //总收款
        totalLate: 0, //总逾期
        totalNotStart: 0, //未开始
        totalOverBudget: 0, //超出预算项目数
        totalPayment: 0, //总付款
        totalPrice: 0, //总金额
        totalProject: 0, //项目总数
        totalReimbursement: 0, //总报销
        totalTerminal: 0, //已取消数
      },
      userId: '',
      powerList: [], //value 1总经理 2财务 3行政
      dataPanelList: [],
      isShow: false
    };
  },
  mounted() {
    this.getPowerList();
    this.getData();
    this.testShare()
    this.getUserId()
    this.getDataPanel()
  },
  methods: {
    getDataPanel() {
      configDetailList(13).then(res => {
        if (res.data.code == 200) {
          this.dataPanelList = res.data.rows
          this.dataPanelList.forEach(it => {
            if (it.commonValue == this.userId) {
              this.isShow = true
            }
          })
        }
      })
    },
    getUserId() {
      this.userId = getToken("userId");
    },
    //获取权限渲染页面
    getPowerList() {
      this.powerList = JSON.parse(getToken("powerList") || "[]");
    },
    goPage(page) {
      if (page == "/dataSummary") {
        this.$router.push({
          path: page,
          query: {
            data: JSON.stringify(this.total),
            year: this.year,
          },
        });
      } else {
        this.$router.push({ path: page });
      }
    },
    getData() {
      getProjectStatistics(this.year).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.data;
        }
      });
    },
    //金额转万元单位
    getTenThousand(m) {
      return tenThousand(m);
    },
    goDataPanel() {
      this.$router.push({ path: "/dataAnalysis" });
    },
    testShare() {
      ddShare(location.href, this.title)
    }
  },
};
</script>

<style lang="less" scoped>
.bkColor {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
}

.logo {
  width: 100vw;
  height: 10vh;
  background: #fff;
  line-height: 10vh;
  position: relative;
  margin-bottom: 2vh;
}

.logoImg {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  /* right: 0; */
  bottom: 0;
  left: 3vw;
  margin: auto;
}

.logoTitle {
  position: absolute;
  top: 0;
  /* right: 0; */
  bottom: 0;
  left: 20vw;
  margin: auto;
  font-weight: 500;
}

.statistics {
  width: 100vw;
  height: 31vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
}

.statisticsH {
  width: 100vw;
  height: 8vh;
  border-bottom: 1px solid #f6f6f6;
  display: flex;
  justify-content: space-between;
}

.Himg {
  width: 17px;
  height: 22px;
}

.dataDisplay {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3vh;
}

.dataM {
  width: 33.3vw;
  height: 10vh;
  text-align: left;
  padding-left: 5vw;
  box-sizing: border-box;
}

.number {
  font-size: 17px;
  // font-weight: 600;
  overflow: hidden; //超出隐藏
  white-space: nowrap; //不换行，同一行展示
  text-overflow: ellipsis; //设置超出部分以省略号展示
}

.ModuleOptions {
  width: 100vw;
  height: 55vh;
  padding: 2vh 5vw 4vh;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.options {
  width: 40vw;
  height: 8vh;
  background: #f6f6f6;
  font-size: 18px;
  line-height: 8vh;

  img {
    width: 18px;
    height: 18px;
    margin-right: 1vw;
  }
}
</style>